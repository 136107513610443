import Swal from "sweetalert2"; // eslint-disable-line no-unused-vars
import "nouislider";
import VoxeetSDK from "@voxeet/voxeet-web-sdk";
import crel from "crel";
import { Chart, registerables } from "chart.js";

window.jqueryDatepicker = require("jquery-datepicker");

window.noUiSlider = require("nouislider");

window.crel = crel;

window.VoxeetSDK = VoxeetSDK;
window.Swal = Swal;

Chart.register(...registerables);
window.Chart = Chart;
